// AuthMixin.js
import { onBeforeRouteEnter } from 'vue-router';
import store from '@/store'; // update according to actual store location

export default {
    setup() {
        onBeforeRouteEnter((to, from, next) => {
            const token = !!store.state.token;

            if (!token) {
                store.dispatch('refreshToken').then(() => {
                    if (store.state.token) {
                        next();
                    } else {
                        next('/login');
                    }
                });
            } else {
                next();
            }
        });
    },
}