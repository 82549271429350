<template>
  <div>
    <div class="button-container">
      <button @click="logout" class="nav-button">Logout</button>
    </div>
    <div v-if="logoutMessage" class="message">{{ logoutMessage }}</div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: 'LogoutComponent',
  data() {
    return {
      logoutMessage: ''
    };
  },
  methods: {
    logout() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refreshToken;
      api({
        method: 'post',
        url: `${process.env.VUE_APP_API_BASE_URL}/auth/logout`,
        headers: { Authorization: `Bearer ${token}` },
        data: {refreshToken: refreshToken},
        withCredentials: true
      })
          .then(() => {
            this.$store.commit('clearToken');
            // handle successful logout here, maybe redirect to login page?
            this.logoutMessage = 'Logout Successful, redirecting to login';
            setTimeout(() => {
              this.$router.push('/login');
            }, 100);
          })
          .catch(error => {
            // handle error here
            console.error('Logout failed', error);
            this.$store.commit('clearToken');
          });
    }
  },
};
</script>
<style scoped>
.nav-button {
  text-decoration: none;
  color: white;
  border: 1px solid white;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}
</style>