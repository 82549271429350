import axios from 'axios';
import store from './store'; // assuming the store.js is on the same level

// create a new axios instance
const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true
});

// request interceptors are run before the request is made.
api.interceptors.request.use((config) => {
    config.headers = config.headers || {};
    config.headers.common = config.headers.common || {};

    const token = store.state.token;
    if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
// api.interceptors.request.use(async (config) => {
//     config.headers['Authorization'] = `Bearer ${store.state.token}`;
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// Add a response interceptor
api.interceptors.response.use(response => response, async error => {
    if (error.response && error.response.status === 401) {

        // Try refreshing the token once before rejecting.
        if (!error.config._retry) {
            error.config._retry = true;

            try {
                await store.dispatch('refreshToken');
                error.config.headers['Authorization'] = `Bearer ${store.state.token}`;
                return api.request(error.config);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }
    }

    return Promise.reject(error);
});

export default api;