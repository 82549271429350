<template>
  <div>
    <h2>Register a Company</h2>
    <form @submit.prevent="registerCompany" v-if="!registrationSuccess">
      <div class="inputs-container">
        <input type="text" v-model="name" placeholder="Numele Companiei" required/>
<!--        <input type="password" v-model="password" placeholder="Parola" required/>-->
        <input type="text" v-model="identificationNumber" placeholder="CUI" required>
        <input type="text" v-model="address" placeholder="Adresa" required>
        <input type="text" v-model="city" placeholder="Localitate" required>
        <input type="text" v-model="phoneNumber" placeholder="Numar de Telefon" required>
        <div class="button-container">
          <button type="submit">Register</button>
        </div>
      </div>
    </form>
    <div v-if="registrationSuccess">
      <p>{{ successMessage }}</p>
      <p>Redirecting to user registration...</p>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      name: '',
      identificationNumber: '',
      address: '',
      city: '',
      phoneNumber: '',
      registrationSuccess: false,
      successMessage: ''
    };
  },
  methods: {
    async registerCompany() {
      try {
        const user = {
          name: this.name,
          identificationNumber: this.identificationNumber,
          address: this.address,
          city: this.city,
          phoneNumber: this.phoneNumber
        };
        const response = await api.post(`${process.env.VUE_APP_API_BASE_URL}/companies/create`, user);
        this.registrationSuccess = true;
        this.successMessage = response.data;

        setTimeout(() => {
          this.$router.push({
            name: 'RegisterUser',
            params: {
              companyId: response.data.id
            }
          });
        }, 1500);
      } catch (error) {
        console.error('Registration error:', error);
      }
    }
  }
};
</script>

<style>
.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  display: block;
  margin-bottom: 10px;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: center;
  bottom: 20px;
}


button {
  padding: 10px 15px;
  background-color: #1d3e1d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>