import {createStore} from 'vuex';
import api from "@/api";
import store from "core-js/internals/shared-store";

export default createStore({
    state: {token: null, refreshToken: localStorage.getItem('refreshToken') || null,
        isUserLoggedIn: false},
    mutations: {
        setToken(state, token) {
            state.token = token;
            state.isUserLoggedIn = !!token;
        },
        setUser(state, user) {
            state.user = user;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
            localStorage.setItem('refreshToken', refreshToken);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        clearToken(state) {
            state.token = null;
            state.refreshToken = null;
            state.isUserLoggedIn = false;
        }
    }, actions: {
        setToken({commit}, token) {
            commit('setToken', token);
        },
        setUser({commit}, user) {
            commit('setUser', user);
        },
        setRefreshToken({commit}, refreshToken) {
            commit('setRefreshToken', refreshToken);
        },
        async refreshToken({commit}) {
            if(!store.state.refreshToken){
                console.log("Refresh token not available.")
                return;
            }

            try{
                const response = await api.post('/auth/refresh-token');
                commit('setToken', response.data.token);
                commit('setRefreshToken', response.data.refreshToken); // update the refreshToken in Vuex
            } catch(error){
                // handle error
            }
        },
        async login({commit}, {username, password}) {
            try {
                const response = await api.post(`${process.env.VUE_APP_API_BASE_URL}/auth/login`, {
                    username: username,
                    password: password
                });

                commit('setToken', response.data.token);
                return response; // you might want to return this response to handle any post-login logic
            } catch (error) {
                // handle error
                console.error(error);
                throw error; // you might want to throw this error to handle error in your component
            }
        },
    }
})
;