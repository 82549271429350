<template>
  <nav v-if="isLoggedIn">
    <ul>
      <li>
        <router-link to="/dashboard" class="nav-button" exact>Dashboard</router-link>
      </li>
      <li>
        <logout-component></logout-component>
      </li>
    </ul>
  </nav>
</template>

<script>
import LogoutComponent from "@/components/LogoutComponent.vue";

export default {
  name: 'NavigationBar',
  components: {LogoutComponent},
  computed: {
    isLoggedIn() {
      return !!this.$store.state.token;
    }
  }
}
</script>

<style scoped>
nav {
  background-color: #1d3e1d;
  padding: 10px 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.nav-button {
  text-decoration: none;
  color: white;
  border: 1px solid white;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.router-link-active, .router-link-exact-active {
  border: 1px solid white;
  color: white;
  border-radius: 5px;
}
</style>