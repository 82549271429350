<template>
  <div>
    <h1>Dashboard</h1>
    <div class="buttons">
      <button class="add-contract" @click="openForm('create')">Add Contract</button>
      <button class="edit-contract" v-if="selectedContracts.length === 1" @click="openForm('edit')">Edit Contract</button>
      <button class="delete-contract" v-if="selectedContracts.length > 0" @click="deleteContracts">Delete Contract</button>
    </div>
    <table>
      <thead>
      <tr>
        <th><input type="checkbox" :checked="allSelected" @change="selectAllContracts"></th>
        <th>Contract ID</th>
        <th>Cod Client</th>
        <th>Autor</th>
        <th>Suprafata</th>
        <th>Localitate</th>
        <th>Numar Inregistrare</th>
        <th>Data Inregistrare</th>
        <th>Data Inceput</th>
        <th>Data Expirare</th>
        <th>Durata</th>
        <th>Cantitate</th>
        <th>Observatii</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="contract in contracts" :key="contract.id">
        <td><input type="checkbox" :value="contract.id" :checked="isSelected(contract.id)" @change="toggleSelection(contract.id)"></td>
        <td>{{ contract.id }}</td>
        <td>{{ contract.codClient }}</td>
        <td>{{ contract.autor }}</td>
        <td>{{ contract.suprafata }}</td>
        <td>{{ contract.localitate }}</td>
        <td>{{ contract.numarInregistrare }}</td>
        <td>{{ contract.dataInregistrare }}</td>
        <td>{{ contract.dataInceput }}</td>
        <td>{{ contract.dataExpirare }}</td>
        <td>{{ contract.durata }}</td>
        <td>{{ contract.cantitate }}</td>
        <td>{{ contract.observatii }}</td>
        <td>{{ contract.status }}</td>
      </tr>
      </tbody>
    </table>

    <div v-if="showForm" class="modal">
      <div class="modal-content">
        <span class="close" @click="showForm = false">&times;</span>
        <h2>{{ formMode === 'create' ? 'Add a New Contract' : 'Edit Contract' }}</h2>
        <form @submit.prevent="formMode === 'create' ? addContract() : editContract()">
          <label for="codClient">Cod Client:</label>
          <input type="text" v-model="currentContract.codClient" required />
          <label for="autor">Autor:</label>
          <input type="text" v-model="currentContract.autor" required />
          <label for="suprafata">Suprafata:</label>
          <input type="number" v-model="currentContract.suprafata" required />
          <label for="localitate">Localitate:</label>
          <input type="text" v-model="currentContract.localitate" required />
          <label for="numarInregistrare">Numar Inregistrare:</label>
          <input type="text" v-model="currentContract.numarInregistrare" required />
          <label for="dataInregistrare">Data Inregistrare:</label>
          <input type="date" v-model="currentContract.dataInregistrare" required />
          <label for="dataInceput">Data Inceput:</label>
          <input type="date" v-model="currentContract.dataInceput" required />
          <label for="dataExpirare">Data Expirare:</label>
          <input type="date" v-model="currentContract.dataExpirare" required />
          <label for="durata">Durata:</label>
          <input type="number" v-model="currentContract.durata" required />
          <label for="cantitate">Cantitate:</label>
          <input type="number" v-model="currentContract.cantitate" required />
          <label for="observatii">Observatii:</label>
          <input type="text" v-model="currentContract.observatii" />
          <button type="submit">{{ formMode === 'create' ? 'Save Contract' : 'Update Contract' }}</button>
        </form>
      </div>
    </div>

  </div>
</template>

// toDo check on refresh
<script>
import api from "@/api";
import AuthMixin from "@/components/AuthMixin";
export default {
  mixins: [AuthMixin],
  data() {
    return {
      contracts: [],
      selectedContracts: [],
      showForm: false,
      formMode: 'create',
      currentContract: this.getEmptyContractObject()
    };
  },
  computed: {
    allSelected() {
      return this.selectedContracts.length === this.contracts.length;
    }
  },
  mounted() {
    this.fetchContracts();
  },
  methods: {
    getEmptyContractObject() {
      return {
        codClient: '',
        autor: '',
        suprafata: 0,
        localitate: '',
        numarInregistrare: '',
        dataInregistrare: '',
        dataInceput: '',
        dataExpirare: '',
        durata: 0,
        cantitate: 0,
        observatii: ''
      };
    },
    fetchContracts() {
      const token = this.$store.state.token;
      api.get(`${process.env.VUE_APP_API_BASE_URL}/contracts/search`, {
        headers: {Authorization: `Bearer ${token}`}
      })
          .then(response => {
            this.contracts = response.data.content;
          })
          .catch(error => {
            console.error('Error fetching contracts:', error);
          });
    },
    openForm(mode) {
      this.formMode = mode;
      if (mode === 'edit' && this.selectedContracts.length === 1) {
        const contractId = this.selectedContracts[0];
        const contract = this.contracts.find(c => c.id === contractId);
        this.currentContract = { ...contract };
      } else {
        this.resetForm();
      }
      this.showForm = true;
    },
    resetForm() {
      this.currentContract = {
        codClient: '',
        autor: '',
        suprafata: 0,
        localitate: '',
        numarInregistrare: '',
        dataInregistrare: '',
        dataInceput: '',
        dataExpirare: '',
        durata: 0,
        cantitate: 0,
        observatii: ''
      };
    },
    addContract() {
      const token = this.$store.state.token;
      api.post(`${process.env.VUE_APP_API_BASE_URL}/contracts/save`, this.currentContract, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(() => {
        this.fetchContracts();
        this.showForm = false;
      })
      .catch((error) => {
        console.error("Error adding contract:", error);
      });
    },
    editContract() {
      const token = this.$store.state.token;
      const contractId = this.selectedContracts[0];
      console.log('Current Contract:', this.currentContract);
      api.put(`${process.env.VUE_APP_API_BASE_URL}/contracts/${contractId}`, this.currentContract, {
        headers: { Authorization: `Bearer ${token}`,
        "Content-Type": "application/json" }
      })
      .then(() => {
        this.fetchContracts();
        this.showForm = false;
      })
      .catch(error => {
        console.error("Error editing contract:", error);
      });
    },
    toggleSelection(contractId) {
      if (this.selectedContracts.includes(contractId)) {
        this.selectedContracts = this.selectedContracts.filter(id => id !== contractId);
      } else {
        this.selectedContracts.push(contractId);
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedContracts = this.contracts.map(contract => contract.id);
      } else {
        this.selectedContracts = [];
      }
    },
    isSelected(contractId) {
      return this.selectedContracts.includes(contractId);
    },
    deleteContracts() {
      const token = this.$store.state.token;
      api.delete(`${process.env.VUE_APP_API_BASE_URL}/contracts`, {
          headers: { Authorization: `Bearer ${token}` },
          data: this.selectedContracts
        })
      .then(() => {
        this.fetchContracts();
        this.selectedContracts = [];
      })
      .catch((error) => {
        console.error("Error deleting contracts:", error);
      });
    },
    beforeRouteUpdate(to, from, next) {
      // This will be invoked when we stay on the same route, but the route parameter or query has changed
      // It may also be invoked multiple times, which is handy if you want to fetch data when clicking on the 'Dashboard' button even when we're already on that route
      this.fetchContracts();
      next();
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #1d3e1d;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #adaaaa;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }

  table td {
    display: block;
    text-align: right;
    font-size: 0.8em;
    border-bottom: 1px dotted #ccc;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.edit-contract {
  background-color: #d3ca20;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
.edit-contract:hover {
  background-color: #8c8514;
}

.delete-contract {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
.delete-contract:hover {
  background-color: #e53935;
}
</style>