<template>
  <form @submit.prevent="registerUser">
    <input type="text" v-model="username" placeholder="Username" required/>
    <input type="password" v-model="password" placeholder="Password" required/>
    <button type="submit">Register User</button>
  </form>
  <div v-if="registrationSuccess">
    <p>{{ successMessage }}</p>
    <p>Redirecting to login...</p>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      username: '',
      password: '',
      registrationSuccess: false,
      successMessage: ''
    };
  },
  methods: {
    async registerUser() {
      try {
        const user = {
          username: this.username,
          password: this.password
        };
        console.log("COmpanyId: ", this.$route.params.companyId);
        await api.post(`${process.env.VUE_APP_API_BASE_URL}/auth/register/${this.$route.params.companyId}`, user);
        this.registrationSuccess = true;
        this.successMessage = 'User registered successfully! Redirecting to login...';

        setTimeout(() => {
          this.$router.push('/login');
        }, 1500);
      } catch (error) {
        console.error('Registration error:', error);
      }
    }
  }
};
</script>



<style scoped>

</style>