<template>
  <div id="app">
    <NavigationBar v-if="isLoggedIn" />
    <router-view></router-view> <!-- This tag renders the current route's component -->
  </div>
</template>

<script>

import NavigationBar from "@/components/NavigationBar.vue";
import store from "@/store";
import {onMounted, ref} from "vue";

export default {
  name: 'App',
  components: {NavigationBar},
  data() {
    return {
    };
  },
  computed: {
    isLoggedIn() {
      return !!store.state.token;
    }
  },
  provide() {
    return {
      setAuthenticated: this.setAuthenticated,
      isLoggedIn: this.isLoggedIn
    };
  },
  methods: {
    setAuthenticated() {
    }
  },
  watch: {
    isLoggedIn() {
      this.isLoggedIn = !!store.state.token;
    }
  },
  setup() {
    const loaded = ref(false);
    onMounted(async () => {
      // Update: Only dispatch refresh token if a refresh token is already present
      if (store.state.refreshToken) {
        console.log("refreshing token");
        await store.dispatch('refreshToken');
        loaded.value = true;
      }
    });
    return { loaded };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
