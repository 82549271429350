import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/components/DashboardComp.vue';
import LoginComponent from "@/components/LoginComponent.vue";
import RegisterComponent from "@/components/RegisterComponent.vue";
import store from "@/store";
import UserRegistrationComponent from "@/components/UserRegistrationComponent.vue";

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginComponent,
        meta: { requiresAuth: false }
    },

    {
        path: '/',
        redirect: '/login',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = !!store.state.token;

            if (isAuthenticated) {
                console.log("User authenticated - should redirect to dashboard")
                next('/dashboard');
            } else {
                next();
            }
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterComponent,
        meta: { requiresAuth: false }
    },
    {
        path: '/register/:companyId',
        name: 'RegisterUser',
        component: UserRegistrationComponent
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = !!store.state.token;

    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else if (!requiresAuth && isAuthenticated && to.path === '/login') {
        next('/dashboard');
    } else {
        next();
    }
});


export default router;