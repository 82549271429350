<template>
  <div>
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="inputs-container">
        <input v-model="username" type="text" placeholder="Username"/>
        <input v-model="password" type="password" placeholder="Password"/>
        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
        <div class="button-container">
          <div class="button-group">
            <button type="submit">Login</button>
            <router-link to="/register" class="button-link">Register Company</router-link>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    clearErrorMessage() {
      this.errorMessage = '';
    },
    async login() {
      try {
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        });
        this.$router.push('/dashboard');
      } catch (error) {
        this.errorMessage = 'Incorrect username or password';
      }
    }
  },
  created() {
    if (this.$store.state.token) {
      this.$router.replace('/');
    }
    this.clearErrorMessage();
  }
};
</script>

<style>
.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  display: block;
  margin-bottom: 10px;
  justify-content: center;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.button-link, button {
  text-decoration: none;
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: inherit;
}

.error {
  color: red;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-group {
  display: flex;
  gap: 10px;
}
</style>